<template>
    <b-container>
      <!-- <h1 class="mb-3">{{ $t('Galeria de Imagens') }}</h1> -->
      
      <!-- Lista de Imagens -->
      <b-row>
        <b-col v-for="image in images" :key="image.id" cols="12" md="6" lg="4" class="mb-4">
          <b-card>
            <b-img :src="image.src" :alt="image.title" fluid @click="openModal(image)" style="cursor: pointer;"></b-img>
            <b-card-text class="mt-2">{{ $t(image.title) }}</b-card-text>
          </b-card>
        </b-col>
      </b-row>
  
      <!-- Modal para Visualizar Imagem -->
      <b-modal v-if="selectedImage" v-model="showModal" size="lg" centered>
        <template v-slot:modal-header>
          <h5 class="modal-title">{{ $t(selectedImage.title) }}</h5>
          <b-button class="close" @click="showModal = false">&times;</b-button>
        </template>
  
        <b-img :src="selectedImage.src" fluid></b-img>
  
        <template v-slot:modal-footer>
          <b-button variant="primary" @click="downloadImage(selectedImage.src)">{{ $t('Download') }}</b-button>
          <b-button variant="secondary" @click="showModal = false">{{ $t('Fechar') }}</b-button>
        </template>
      </b-modal>
    </b-container>
  </template>
  
  <script>
  export default {
    data() {
      return {
        showModal: false,
        selectedImage: null,
        images: [
          {
            id: 7,
            title: 'Evento de lanzamiento',
            src: ('/marketing/images/event_07.12.png')
          },
          {
            id: 6,
            title: 'Image 6',
            src: ('/marketing/images/image01.jpeg')
          },
          {
            id: 5,
            title: 'Image 5',
            src: ('/marketing/images/image02.jpeg')
          },
          {
            id: 4,
            title: 'Image 4',
            src: ('/marketing/images/image03.jpeg')
          },
          {
            id: 3,
            title: 'Image 3',
            src: ('/marketing/images/image04.jpeg')
          },
          {
            id: 2,
            title: 'Image 2',
            src: ('/marketing/images/image05.jpeg')
          },
          {
            id: 1,
            title: 'Image 1',
            src: ('/marketing/images/image06.jpeg')
          },
        ]
      };
    },
    methods: {
      openModal(image) {
        this.selectedImage = image;
        this.showModal = true;
      },
      downloadImage(src) {
        const link = document.createElement('a');
        link.href = src;
        link.download = src.split('/').pop(); // Nome do arquivo baseado no URL da imagem
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  };
  </script>
  
  <style scoped>
  .my-4 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }
  </style>
  